import { Component, Input, OnInit } from '@angular/core';
import { StateUsageCoupon } from '../../models/coupon';

@Component({
  selector: 'aquamore-coupon-usage-state-tag',
  templateUrl: './coupon-usage-state-tag.component.html',
  styleUrls: ['./coupon-usage-state-tag.component.scss']
})
export class CouponUsageStateTagComponent implements OnInit {

  @Input() state: StateUsageCoupon;

  stateUsageCoupon = StateUsageCoupon;

  constructor() { }

  ngOnInit(): void { }

}
