import { LayoutComponent } from '../layout/portal-layout/layout.component';
import { LayoutBlankComponent } from '../layout/blank-layout/layout_blank.component';
import { Routes } from "@angular/router";
export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'admin', pathMatch: 'full' },
            { path: 'admin', loadChildren: () => import('./pages/portal-sections/portal-sections.module').then(m => m.PortalSectionsModule) },
        ]
    },

    {
        path: '',
        component: LayoutBlankComponent,
        children: [
            { path: 'reseller/:idReseller/landing/:companyCode', loadChildren: () => import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule) }
        ]
    },

    {
        path: '',
        component: LayoutBlankComponent,
        children: [
            { path: 'login', loadChildren: () => import('./pages/authentication-sections/login/login.module').then(m => m.LoginModule) },
            { path: 'login/:id', loadChildren: () => import('./pages/authentication-sections/login/login.module').then(m => m.LoginModule) },
            { path: 'register', loadChildren: () => import('./pages/authentication-sections/register/register.module').then(m => m.RegisterModule) },
            { path: 'recover', loadChildren: () => import('./pages/authentication-sections/recover/recover.module').then(m => m.RecoverModule) },
            { path: 'activate/:id', loadChildren: () => import('./pages/authentication-sections/activate/activate.module').then(m => m.ActivateModule) },
            { path: 'lock', loadChildren: () => import('./pages/authentication-sections/lock/lock.module').then(m => m.LockModule) },
            { path: '404', loadChildren: () => import('./pages/error/error404/error404.module').then(m => m.Error404Module) },
            { path: '500', loadChildren: () => import('./pages/error/error500/error500.module').then(m => m.Error500Module) },
            { path: 'choose-reseller', loadChildren: () => import('./pages/authentication-sections/choose-reseller/choose-reseller.module').then(m => m.ChooseResellerModule) },
        ]
    },

    // Not lazy-loaded routes
    /*{ path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },*/

    // Not found
    { path: '**', redirectTo: '404' }

];
