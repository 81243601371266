import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings } from '../../../core/interfaces/admin.interface';
import { VipCardWithdrawal } from '../../../core/interfaces/product.interface';
import { PortalService } from '../../../core/services/portal.service';

@Component({
  selector: 'aquamore-withdrawal-select',
  templateUrl: './withdrawal-select.component.html',
  styleUrls: ['./withdrawal-select.component.scss']
})
export class WithdrawalSelectComponent implements OnInit {

  @Input() withdrawalSelected: MultiselectItem[] = [];
  @Output() withdrawalSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false
  };

  withdrawalList: MultiselectItem[] = [];

  constructor(private portalService: PortalService) { }

  ngOnInit() {
    this.loadMultiselectItems();
  }

  loadMultiselectItems(): void {
    const withdrawalType = VipCardWithdrawal;
    this.withdrawalList = this.portalService.getItemsFromEnum(withdrawalType);
  }

  onWithdrawalSelect(): void {
    this.withdrawalSelectedChange.emit(this.withdrawalSelected);
    this.onSelected.emit(this.withdrawalSelected);
  }

}
