import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings } from '../../../core/interfaces/admin.interface';
import { TranslatorService } from '../../../core/translator/translator.service';

@Component({
  selector: 'aquamore-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {

  @Input() languageSelected: MultiselectItem[] = [];
  @Output() languageSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  languagesList: MultiselectItem[] = [];

  constructor(private translatorService: TranslatorService) { }

  ngOnInit() {
    this.loadMultiselectItems();
  }

  loadMultiselectItems(): void {

    this.languagesList = this.translatorService.getAvailableLanguages().map(lan => {

      return {
        id: lan.code,
        itemName: lan.text
      } as MultiselectItem

    });
  }

  onLanguageSelect(): void {
    this.languageSelectedChange.emit(this.languageSelected);
    this.onSelected.emit(this.languageSelected);
  }

}
