import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Threshold, ThresholdAttribute } from '../../../core/interfaces/product.interface';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'aquamore-threshold-section',
  templateUrl: './threshold-section.component.html',
  styleUrls: ['./threshold-section.component.scss']
})
export class ThresholdSectionComponent implements OnInit, AfterViewInit {

  @Input() thresholdAttributeList: ThresholdAttribute[] = [];
  @Output() thresholdAttributeListChange = new EventEmitter<ThresholdAttribute[]>();

  @Input() thresholdListALLValidity: boolean = false;
  @Output() thresholdListALLValidityChange = new EventEmitter<boolean>();

  @Input() thresholdAttributeNameValidity: boolean = false;
  @Output() thresholdAttributeNameValidityChange = new EventEmitter<boolean>();

  @Input() atLeastOneThresholdValidity: boolean = false;
  @Output() atLeastOneThresholdValidityChange = new EventEmitter<boolean>();

  @Input() hasCarnet: boolean = false;

  maxLength_ThresholdAttributeList: number = 10;
  maxLength_ThresholdList: number = 10;
  thresholListValidity: any[] = [];
  errorKeyThresholdAttribute: string[] = [];

  constructor(private commonService: CommonService) { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.validListThresholdAttribute(this.thresholdAttributeList);
    this.validListThreshold(this.thresholdAttributeList);
  }

  addThresholdAttribute(): void {
    const length = this.thresholdAttributeList.length;
    if (this.maxLength_ThresholdAttributeList < length + 1) {
      return;
    }
    this.thresholdAttributeList.push({
      idProduct: null,
      name: null,
      cumulative: true,
      productThresholds: [],
      hasCarnet: this.hasCarnet,
      carnet: 1
    });
    this.addThreshold(this.thresholdAttributeList.length - 1);
    this.thresholdAttributeList = this.commonService.brkRef(this.thresholdAttributeList);
    this.validListThresholdAttribute(this.thresholdAttributeList);
    this.validListThreshold(this.thresholdAttributeList);
    this.thresholdAttributeListChange.emit(this.thresholdAttributeList);
  }

  deleteThresholdAttribute(index: number): void {
    this.thresholdAttributeList.splice(index, 1);
    this.validListThresholdAttribute(this.thresholdAttributeList);
    this.validListThreshold(this.thresholdAttributeList);
    this.thresholdAttributeListChange.emit(this.thresholdAttributeList);
  }

  thresholdNameChanged(indexTAL: number, value): void {
    this.thresholdAttributeList[indexTAL].name = value;
    this.validListThresholdAttribute(this.thresholdAttributeList);
    this.thresholdAttributeListChange.emit(this.thresholdAttributeList);
  }

  addThreshold(index: number): void {
    const threshold: Threshold[] = this.thresholdAttributeList[index].productThresholds;
    const length = threshold.length;
    let min: number = 0;

    if (this.maxLength_ThresholdList < length + 1) {
      return;
    }

    if (length > 0) {
      const max = threshold[length - 1].max;
      min = max !== null ? max + 1 : null;
    }

    threshold.push({
      name: null,
      min: min,
      max: min + 1
    });
    this.thresholdAttributeList = this.commonService.brkRef(this.thresholdAttributeList);
    this.validListThresholdAttribute(this.thresholdAttributeList);
    this.validListThreshold(this.thresholdAttributeList);
    this.thresholdAttributeListChange.emit(this.thresholdAttributeList);
  }

  thresholdChanged(indexTAL, indexTL, type, value) {
    this.thresholdAttributeList[indexTAL].productThresholds[indexTL][type] = value;
    this.validListThreshold(this.thresholdAttributeList);
    this.thresholdAttributeListChange.emit(this.thresholdAttributeList);
  }

  deleteThreshold(indexTAL: number, indexTL: number): void {
    this.thresholdAttributeList[indexTAL].productThresholds.splice(indexTL, 1);
    this.thresholdAttributeList = this.sortThresholdsList(this.thresholdAttributeList);
    this.validListThresholdAttribute(this.thresholdAttributeList);
    this.validListThreshold(this.thresholdAttributeList);
    this.thresholdAttributeListChange.emit(this.thresholdAttributeList);
  }

  validListThresholdAttribute(thAtList: ThresholdAttribute[]): void {
    const thresholdAttributeList: ThresholdAttribute[] = this.commonService.brkRef(thAtList);
    for (let i = 0; i < thresholdAttributeList.length; i++) {
      const res = thresholdAttributeList.filter(el => el.name === thresholdAttributeList[i].name);
      if (res.length > 1) {
        this.thresholdAttributeNameValidity = false;
        continue;
      } else {
        this.thresholdAttributeNameValidity = true;
      }
    }

    this.thresholdAttributeNameValidityChange.emit(this.thresholdAttributeNameValidity);

    const res = thresholdAttributeList.every(el => el.productThresholds.length > 0);
    this.atLeastOneThresholdValidity = res;

    this.atLeastOneThresholdValidityChange.emit(this.atLeastOneThresholdValidity);
  }

  validListThreshold(thAtList: ThresholdAttribute[]): void {
    const thresholdAttributeList: ThresholdAttribute[] = this.commonService.brkRef(thAtList);
    this.thresholListValidity = [];

    // controllo che i minimi e i massimi siano rispettati
    for (let i = 0; i < thresholdAttributeList.length; i++) {
      const thList: Threshold[] = thresholdAttributeList[i].productThresholds;
      let countMinInf = 0;
      let conutMaxInf = 0;
      let flag = true;
      for (let j = 0; j < thList.length; j++) {
        if (j === 0) {
          thList[j].min = 0;
        }
        const min = thList[j].min;
        const max = thList[j].max;
        if ((min !== null && max !== null) && min >= max) {
          this.setThresholdListValidity(i, "productManagement.thresholdsError.interval");
          flag = false;
          continue;
        }

        countMinInf = min !== null ? countMinInf : ++countMinInf;
        conutMaxInf = max !== null ? conutMaxInf : ++conutMaxInf;
        if (countMinInf > 0 || conutMaxInf > 0) {
          this.setThresholdListValidity(i, "productManagement.thresholdsError.tooManyInf");
          flag = false;
          continue;
        }
      }
      // Controlli superati
      if (flag) {
        if (!this.thresholListValidity[i]) {
          this.thresholListValidity.push({ thVal: true });
        } else {
          this.thresholListValidity[i].thVal = true;
        }
      } else {
        continue;
      }

    }

    // Controllo che la sequenza sia corretta
    thAtList = this.sortThresholdsList(thAtList);
    for (let i = 0; i < thAtList.length; i++) {
      const thList: Threshold[] = thAtList[i].productThresholds;
      let flag = true;
      for (let j = 0; j < thList.length; j++) {
        if (j === 0 && thList[j].min !== 0) {
          this.setThresholdListValidity(i, "productManagement.thresholdsError.sequence");
          flag = false;
          continue;
        }
        if ((j < thList.length - 1) && thList[j].max + 1 !== thList[j + 1].min) {
          this.setThresholdListValidity(i, "productManagement.thresholdsError.sequence");
          flag = false;
          continue;
        }
        if (j === thList.length - 1 && thList[j].max === null) {
          this.setThresholdListValidity(i, "productManagement.thresholdsError.sequence");
          flag = false;
          continue;
        }
      }
      if (flag) {
        // Controlli superati
        if (!this.thresholListValidity[i]) {
          this.thresholListValidity.push({ thVal: true });
        } else {
          this.thresholListValidity[i].thVal = true;
        }
      } else {
        continue;
      }

    }

    this.thresholdListALLValidity = this.thresholListValidity.every(el => el.thVal === true);

    this.thresholdListALLValidityChange.emit(this.thresholdListALLValidity);

  }

  sortThresholdsList(thresholdAttributeList: ThresholdAttribute[]): ThresholdAttribute[] {
    for (let ind = 0; ind < thresholdAttributeList.length; ind++) {
      if (thresholdAttributeList[ind].productThresholds !== null) {
        thresholdAttributeList[ind].productThresholds.sort((i, j) => {
          if (i.min === j.min) {
            return 0;
          }
          if (i.min === null && j.min !== null) {
            return 1;
          }
          if (j.min === null && i.min !== null) {
            return -1;
          }
          return i.min < j.min ? -1 : 1;
        });
      }
    }
    return thresholdAttributeList;
  }

  setThresholdListValidity(index: number, errorKey: string): void {
    if (this.thresholListValidity[index]) {
      this.thresholListValidity[index] = {
        thVal: false,
        thKey: errorKey
      };
    } else {
      this.thresholListValidity.push({
        thVal: false,
        thKey: errorKey
      });
    }
  }

}
