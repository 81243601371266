export interface IMapPoint {
  code: string,
  x: number,
  y: number
  state? : MapPointState,
  idArea?: number
}

export enum MapPointState {
  "FREE" = "FREE",
  "BOOKED" = "BOOKED",
  "PENDING" = "PENDING",
  "SELECTED" = "SELECTED"
}
