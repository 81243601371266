import { AfterViewInit, Component, Input } from '@angular/core';
import { RelatedProductApiService } from '../../services/related-product-api.service';

@Component({
  selector: 'aquamore-free-ticket-section',
  templateUrl: './free-ticket-section.component.html',
  styleUrls: ['./free-ticket-section.component.scss']
})
export class FreeTicketSectionComponent implements AfterViewInit {

  @Input() idProduct: number;

  relatedProducts: any = [];

  constructor(private relatedProductApiService: RelatedProductApiService) { }
  
  ngAfterViewInit(): void {
    this.loadRelated();
  }

  loadRelated() {
    this.relatedProductApiService.lista(this.idProduct).subscribe(products => {
      console.log("PROD: ", products);
      this.relatedProducts = products;
    })
  }

}
