<div class="d-flex align-items-center justify-content-between add-attribute">
  <div class="required-field-txt">(*) {{ 'base.required_field' | translate }}</div>
  <button (click)="addTranslationAttribute()" class="btn btn-info btn-action ml-2" type="button">{{'productManagement.label.add_translation_attribute' | translate}}</button>
</div>
<div class="attribute-list card-body row mt-3">
  <ng-container *ngFor="let att of translationAttributeList; let i = index">
    <div class="d-flex flex-row mb-3 align-items-end w-100">
      <div>
        <a *ngIf="!att.id" (click)="deleteTranslationAttribute(i)" href="#"><i class="icon-close"></i></a>
      </div>
      <div class="col-sm-3 d-flex flex-column p-0 m-0 ml-2">
        <label for="roleUser">{{ 'productManagement.label.attribute' | translate }} *</label>
        <angular2-multiselect required
          name="{{i}}-attribute"
          #attribute="ngModel"
          [(ngModel)]="att.key"
          [data]="multiselectItems.translationAttribute"
          [settings]="multiselectSettings_search"
          (onSelect)="validTranslationAttributeList($event)">
        </angular2-multiselect>
      </div>
      <div class="col-sm-3 d-flex flex-column p-0 m-0 ml-2">
        <label for="roleUser">{{ 'productManagement.label.lang_code' | translate }} *</label>
        <angular2-multiselect required
          name="{{i}}-lang-code"
          #langCode="ngModel"
          [(ngModel)]="att.languageCode"
          [data]="multiselectItems.languageCode"
          [settings]="multiselectSettings_search"
          (onSelect)="validTranslationAttributeList($event)">
        </angular2-multiselect>
      </div>
      <div class="flex-fill d-flex flex-column p-0 m-0 ml-2" *ngIf="!settingService.checkStartsWith(att, 'HTML_')">
        <label for="">{{ 'productManagement.label.description' | translate }}</label>
        <input type="text"
          name="{{i}}-value"
          id="value"
          class="form-control"
          ngModel
          #value="ngModel"
          [(ngModel)]="att.value">
      </div>
    </div>
    <aquamore-html-editor *ngIf="settingService.checkStartsWith(att, 'HTML_')" [(htmlText)]="att.value"></aquamore-html-editor>
  </ng-container>
  <div *ngIf="translationAttributeList.length === 0" class="text-center w-100 m-3">
    <p>{{'productManagement.label.noTranslationAttribute' | translate}}</p>
  </div>
  <div *ngIf="!translationAttributeListValidity " class="text-danger thresholdsError text-center w-100">
    <small>{{ errorKeyTranslationAttribute | translate}}</small>
  </div>
  
</div>