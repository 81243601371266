import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Product } from '../../../core/interfaces/product-sale.interface';
import { DropdownSelector, SelectedProductItem } from '../../../core/interfaces/selector.interface';
import { MultiselectSettings } from '../../../core/interfaces/admin.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-parking-result-card',
  templateUrl: './parking-result-card.component.html',
  styleUrls: ['./parking-result-card.component.scss']
})
export class ParkingResultCardComponent {

  @Input() set result(newvalue: Product) {
    this._result = newvalue;
    this.mapOptions();
  };

  @Output() parkSelect = new EventEmitter<SelectedProductItem>();
  @Output() parkRemove = new EventEmitter<string>();

  modalRef: BsModalRef;
  addEnabled: boolean = true;

  _result: Product;

  options: DropdownSelector[] = [];
  optionSelected: DropdownSelector[] = [];

  multiselectSettings_Qty: MultiselectSettings = {
    singleSelection: true,
    text: this.translate.instant('productManagement.label.select_channel'),
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  constructor(
    private modalService: BsModalService,
    private translate: TranslateService
  ) { }

  mapOptions() {
    if (this._result) {

      this.options = [];

      const limit = Math.min(this._result.customFieldsExternal?.availablePlaces, this._result.customFieldsExternal?.maxQuantity);

      for (let i = 0; i <= limit; i++) { 

        const opt = {
          id: i,
          itemName: `${i} `
        } as DropdownSelector

        this.options.push(opt);

      }

      if (this.options.length) {
        this.optionSelected = [this.options[0]];
      }
    }
  }

  isParkFull(): boolean {
    return this.options.length === 1 && this.options[0].itemName.trim() === '0';
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-lg modal-dialog-centered modal-dialog-scrollable resized-modal' }));
  }

  selectParkAmount() {
    const amount = +this.optionSelected[0].itemName;

    this._result.customFieldsExternal.quantitySelected = amount;

    if (amount) {
      this.parkSelect.emit({product: this._result, amount});
    } else {
      this.parkRemove.emit(this._result.itemCode);
    }
  }

  deselectParkAmount() {
    this.parkRemove.emit(this._result.itemCode);
  }

}
