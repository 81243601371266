import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 's1-input-text',
  templateUrl: './s1-input-text.component.html',
  styleUrls: ['./s1-input-text.component.scss']
})
export class S1InputTextComponent implements OnInit {

  @Input() text: string;
  @Input() label: string;
  @Input() type: string = "text";

  @Output() textChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  updateText() {
    this.textChange.emit(this.text);
  }

}
