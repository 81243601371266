import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IBgyTopExtraInfo {
  date: Date,
  flight: string,
  note: string,
  groupLeaderName: string
}

@Component({
  selector: 'aquamore-product-sales-bgy-top-section',
  templateUrl: './product-sales-bgy-top-section.component.html',
  styleUrls: ['./product-sales-bgy-top-section.component.scss']
})
export class ProductSalesBgyTopSectionComponent implements OnInit {

  @Input() info: IBgyTopExtraInfo = {
    date: new Date(),
    flight: null,
    note: "",
    groupLeaderName: null
  }

  @Output() infoChange = new EventEmitter<IBgyTopExtraInfo>();

  constructor() { }

  ngOnInit() {
  }

  updateInfo() {
    console.log("Info UPd: ", this.info);
    this.infoChange.emit(this.info);
  }

}
