import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductGroupFamily, TranslationAttributeFamilyKey } from '@app/core/interfaces/product-sale.interface';
import { IMapPoint, MapPointState } from '@app/shared/models/map-point';
import { IAreaAvailabilityParams, PurchaseApiService } from '@app/shared/services/purchase-api.service';
import { PanZoomAPI, PanZoomConfig } from 'ngx-panzoom';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-seat-map',
  templateUrl: './seat-map.component.html',
  styleUrls: ['./seat-map.component.scss']
})
export class SeatMapComponent implements OnInit, AfterViewInit {

  @Input() set family(newValue: ProductGroupFamily) {
    this._family = newValue;
    this.mapUrl = this._family.translationAttribute?.find(attr => attr.key == TranslationAttributeFamilyKey.IMG_FAMILY_AREA)?.value;
    this.loadArea();
  };

  @Input() set date(newValue: Date) {
    this._date = newValue;
    this.loadPointsAvaiability();
  }

  @Input() selectable: boolean = false;

  @Output() pointSelected = new EventEmitter<IMapPoint>();
  @Output() pointDeselected = new EventEmitter<IMapPoint>();
  
  _family: ProductGroupFamily;
  _date: Date;
  area: any;
  mapUrl: string;
  points: IMapPoint[] = [];

  panZoomConfig: PanZoomConfig = new PanZoomConfig();
  private panZoomAPI: PanZoomAPI;
  private apiSubscription: Subscription;

  class: string = "available";
  innerWidth;

  offset = 20;

  constructor(private purchaseApiService: PurchaseApiService) { }

  ngAfterViewInit(): void {
    //this.onCenterContentClicked();
    this.setDefaultView();
  }

  ngOnInit(): void {

    this.apiSubscription = this.panZoomConfig.api.subscribe( (api: PanZoomAPI) => {
      this.panZoomAPI = api 
    
      //this.setDefaultView()
    });

    this.innerWidth = window.innerWidth;
    console.log("Inner: ", this.innerWidth);

    //this.setDefaultView()

  }

  loadArea() {

    this.purchaseApiService.allArea().subscribe(areas => {

      this.area = areas.find(area => area.idProductFamily == this._family?.id);
      this.loadPointsAvaiability();

    })

  }

  loadPointsAvaiability() {

    if (this.area && this._date) {

      const params: IAreaAvailabilityParams = {
        idArea: this.area?.id,
        date: this._date
      }

      this.purchaseApiService.areaAvailability(params).subscribe(area => {

        this.points = area?.spotList.map(p => { 
          return {
            ... p,
            idArea: this.area?.id
          }
        });

      })

    }

  }

  unlockSeat(point: IMapPoint) {

    const found = this.points.find(p => p.code == point.code);
    
    if (found) {

      found.state = MapPointState.FREE;

    }

  }

  onCenterContentClicked(): void {
    this.panZoomAPI.centerContent();
    
  }

  onZoomInClicked(): void {
    this.panZoomAPI.zoomIn('viewCenter');
  }

  onZoomOutClicked(): void {
    this.panZoomAPI.zoomOut('viewCenter');
  }

  onResetViewClicked(): void {
    this.setDefaultView();
  }

  onPanRight100Clicked(): void {
    this.panZoomAPI.panDelta( { x: 100, y: 0 } );
  }

  onPanLeft100Clicked(): void {
    this.panZoomAPI.panDelta( { x: -100, y: 0 } );
  }

  onPanUp100Clicked(): void {
    this.panZoomAPI.panDelta( { x: 0, y: -100 } );
  }

  onPanDown100Clicked(): void {
    this.panZoomAPI.panDelta( { x: 0, y: 100 } );
  }

  setDefaultView() {
    if (this.innerWidth < 400) {
      this.panZoomAPI.changeZoomLevel(1, { x: 0, y: 0 });
      //this.panZoomAPI?.zoomToFit({ x: this.innerWidth / 2, y: 0, width: this.innerWidth, height: this.innerWidth * 0.75});
      //this.panZoomAPI.centerContent();
    } else {
      this.panZoomAPI.resetView();
      
    }
  }

  selected(text) {
    console.log(text)
    this.class = "booked";
  }

  addPointToCart(point: IMapPoint) {

    console.log("selected: ", point);
    this.pointSelected.emit(point);

  }

  removePointFromCart(point: IMapPoint) {

    console.log("de selected: ", point);
    this.pointDeselected.emit(point);

  }

}
