export enum AccessChannelResponse {
  APPROVED_ADMIN = "APPROVED_ADMIN",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCELED_ADMIN = "CANCELED_ADMIN",
  APPROVED_OFFLINE = "APPROVED_OFFLINE",
  REJECTED_OFFLINE = "REJECTED_OFFLINE",
  CODE_NOT_EXIST = "CODE_NOT_EXIST",
  EXCEED_OBLITERATION = "EXCEED_OBLITERATION",
  REJECTED_UNKNOW_ACCESS_CHANNEL = "REJECTED_UNKNOW_ACCESS_CHANNEL",
  REJECTED_UNKNOW_ACCESS_CHANNEL_OFFLINE = "REJECTED_UNKNOW_ACCESS_CHANNEL_OFFLINE",
  REJECTED_ACCESS_CHANNEL_NOTVALID = "REJECTED_ACCESS_CHANNEL_NOTVALID",
  REJECTED_ACCESS_CHANNEL_NOTVALID_OFFLINE = "REJECTED_ACCESS_CHANNEL_NOTVALID_OFFLINE",
  OUT_OF_VALIDITY = "OUT_OF_VALIDITY",
  OUT_OF_VALIDITY_OFFLINE = "OUT_OF_VALIDITY_OFFLINE",
  OUT_OF_USABILITY = "OUT_OF_USABILITY",
  OUT_OF_USABILITY_OFFLINE = "OUT_OF_USABILITY_OFFLINE",
  APPROVED_FORCED = "APPROVED_FORCED",
  OBLITERATION_ALREADY_FORCED = "OBLITERATION_ALREADY_FORCED",
  REJECTED_MAX_OBLITERATION_TODAY_ACCESS_CHANNEL = "REJECTED_MAX_OBLITERATION_TODAY_ACCESS_CHANNEL",
}

export interface Obliteration {
  id?: number;
  idTicket?: number;
  ticketCode?: string;
  ticketShortCode?: string;
  orderCode?: string;
  accessChannel?: string;
  obliterationDate?: number;
  statusObliteration?: AccessChannelResponse;
  tsCreated?: number;
}

export interface DoObliteration {
  code?: string;
  shortCode?: string;
  dateTimeObliteration?: number;
  //accessChannel?: string;
  idProductFamily?: number
  isForced?: boolean;
  isCancellation?: boolean;
  idObliteration?: number;
}
