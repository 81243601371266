import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings } from '../../../core/interfaces/admin.interface';
import { CouponType } from '../../models/coupon';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'aquamore-coupon-type-select',
  templateUrl: './coupon-type-select.component.html',
  styleUrls: ['./coupon-type-select.component.scss']
})
export class CouponTypeSelectComponent implements OnInit {

  @Input() typeSelected: MultiselectItem[] = [];

  @Output() typeSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  typeList: MultiselectItem[] = [];

  constructor(private translate: TranslateService) { 

    this.typeList = [
      { id: CouponType.AMOUNT, itemName: this.translate.instant('multiselect.couponType.' + CouponType.AMOUNT) },
      { id: CouponType.PERCENTAGE, itemName: this.translate.instant('multiselect.couponType.' + CouponType.PERCENTAGE) }
    ]

  }

  ngOnInit(): void { }

  onTypeSelect(): void {
    this.typeSelectedChange.emit(this.typeSelected);
    this.onSelected.emit(this.typeSelected);
  }

}
