import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings, Multiselect } from '../../../core/interfaces/admin.interface';
import { PortalService } from '../../../core/services/portal.service';

@Component({
  selector: 'aquamore-product-family-select',
  templateUrl: './product-family-select.component.html',
  styleUrls: ['./product-family-select.component.scss']
})
export class ProductFamilySelectComponent implements OnInit {

  @Input() set preselectedFamilies(newValue: number[]) {
    this.selectFromId(newValue);
  }

  @Input() familiesSelected: MultiselectItem[] = [];

  @Input() set singleSelection(newValue: boolean) {
    this.multiselectSettings_base.singleSelection = newValue;
    this.multiselectSettings_base = {... this.multiselectSettings_base};
  }

  @Output() familiesSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: false,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  list: MultiselectItem[] = [];

  preselectedIds: number[];

  constructor(public portalService: PortalService) { 

    this.load();

  }

  ngOnInit(): void { }

  onElementSelect(): void {
    this.familiesSelectedChange.emit(this.familiesSelected);
    this.onSelected.emit(this.familiesSelected);
  }

  load() {

    this.portalService.getMultiselectItems(Multiselect.PRODUCT_FAMILY).subscribe((response) => {

      this.list = response?.data.map(family => {

        return {
          id: family.id,
          itemName: family.name
        } as MultiselectItem

      })

      if (this.preselectedIds?.length && this.familiesSelected.length == 0) {
        this.selectFromId(this.preselectedIds);
      }

    },
    (error) => {
      console.log("errore", error);
    });

  }

  selectFromId(ids: number[], withEvent: boolean = true) {

    this.preselectedIds = ids;

    this.familiesSelected = this.list.filter(c => ids?.includes(c.id));
    if (withEvent) {
      this.onElementSelect();
    }

  }

}
