<div class="row rounded border pt-3 {{ _relatedProduct ? '' : 'bg-gray-lighter' }}">
  <!--<aquamore-user-reseller-select class="col-md-4" [(resellerSelected)]="resellers" [singleSelection]="true"></aquamore-user-reseller-select>-->
  <aquamore-price-list-select #pricesSelect class="col-md-3" [(listsSelected)]="prices" [preselectedLists]="[_relatedProduct?.idPriceList]" [singleSelection]="true"></aquamore-price-list-select>
  <aquamore-product-select #productsSelect class="col-md-3" [disabled]="!prices?.length" [(productsSelected)]="products" [preselectedProducts]="[_relatedProduct?.idRelatedProduct]" [idPriceList]="prices.length ? prices[0].id : null" [singleSelection]="true"></aquamore-product-select>
  <aquamore-product-threshold-select class="col-md-3" [disabled]="products?.length == 0" [(thresholdsSelected)]="thresholds" [preselectedThresholds]="[_relatedProduct?.idProductThreshold]" [idProduct]="products.length ? products[0].id : null" [singleSelection]="true" ></aquamore-product-threshold-select>
  <div class="col-md-3 flex-fill d-flex flex-column">
    <label for="quantity">{{
        'form.quantity' | translate }}</label>
    <input type="text"
        name="quantity"
        id=""
        class="form-control"
        [(ngModel)]="quantity"
        [disabled]="!thresholds?.length" />
  </div>
  <div class="col-md-12 text-center pb-2">
    <button
      class="btn btn-info"
      type="button"
      [disabled]="!(prices?.length && products?.length && thresholds?.length && quantity > 0)"
      (click)="save()">
      <span>{{ (_relatedProduct ? 'common.edit' : 'common.create') | translate}}</span>
    </button>
    <button
      class="btn btn-danger ml-3"
      type="button"
      *ngIf="_relatedProduct"
      (click)="askDelete()">
      <span>{{ 'common.delete' | translate}}</span>
    </button>
  </div>
</div>

