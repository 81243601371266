import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMapPoint, MapPointState } from '../../models/map-point';

@Component({
  selector: 'map-point',
  templateUrl: './map-point.component.html',
  styleUrls: ['./map-point.component.scss']
})
export class MapPointComponent implements OnInit {

  @Input() point: IMapPoint;
  @Input() selectable: boolean = false;
  @Output() pointSelected = new EventEmitter<IMapPoint>();
  @Output() pointDeselected = new EventEmitter<IMapPoint>();

  mapPointState = MapPointState;

  constructor() { }

  ngOnInit(): void {
  }

  selected() {

    if (this.point.state == MapPointState.SELECTED) {
      this.point.state = MapPointState.FREE;
      this.pointDeselected.emit(this.point);
    }

    if (!this.selectable) {
      return;
    }

    if (this.point.state == MapPointState.FREE) {
      this.point.state = MapPointState.SELECTED;
      this.pointSelected.emit(this.point);
    } 

  }

}
