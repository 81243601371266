import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { IS1FileData } from '../../interfaces/s1-file-data.interfaces';

export enum S1FileType {
  all = "*",
  image = "image/*",
  pdf = ".pdf"
}

@Component({
  selector: 's1-input-file',
  templateUrl: './s1-input-file.component.html',
  styleUrls: ['./s1-input-file.component.scss']
})
export class S1InputFileComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() id: string = '';
  @Input() readonly: boolean = false;
  @Input() set filesAccepted(newValue: S1FileType[]) {

    this._filesAccepted = newValue.join(", ");

  }

  @Output() onFileLoaded = new EventEmitter<IS1FileData>();
  @Output() filesizeError = new EventEmitter<void>();

  _filesAccepted: string = "*";
  fileName: string;

  constructor(private toasterService: ToasterService, private translateService: TranslateService) { }

  ngOnInit(): void { }

  readFile(event: any) {
    const file = event.target.files[0];
    this.fileName = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {

      if (file.type.includes("image")) {
        if (file.size > 1000000) {
          this.showFilesizeError();
          this.filesizeError.emit();
        } else {
          this.manageFileLoaded(reader, file);
        }
      } else {
        this.manageFileLoaded(reader, file);
      }
    };
  }

  // ---------- PRIVATE METHODS ---------- //

  /** Method executed after a file input is succesfully completed */
  private manageFileLoaded(reader: FileReader, file: any): void {
    // console.log(reader.result);

    const result = reader.result;
    const ext = file.name.match(/\.[0-9a-z]+$/i);

    if (result && ext && ext.length) {
      this.onFileLoaded.emit(
        {
          fileData: result.toString().replace(/^data:.*?;base64,/, ''),
          extension: ext[0].toLowerCase() || null
        } as IS1FileData
      );
    }
  }

  /** Method that displays an error toast when the selected file is too big */
  private showFilesizeError(): void {
    this.toasterService.pop(
      'error',
      this.translateService.instant('error.file_import_error'),
      this.translateService.instant('error.filesize_error')
    );
  }

}
