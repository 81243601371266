import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1/services/s1-http-client.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IAreaAvailabilityParams {
  idArea: number,
  date: Date | string
}

enum AreaApi {
  all = "/all",
  availability = "/availability"
}

@Injectable({
  providedIn: 'root'
})
export class PurchaseApiService {

  path = "/purchase/area"

  constructor(private s1HttpClient: S1HttpClientService) { }

  public allArea(): Observable<any> {

    return this.s1HttpClient.get(`${this.path}${AreaApi.all}`, {}, true, false, false).pipe(
      map(response => response.data)
    );

  }

  public areaAvailability(params: IAreaAvailabilityParams): Observable<any> {

    params.date = formatDate(params.date, "YYYY-MM-dd", "it-IT");

    return this.s1HttpClient.get(`${this.path}${AreaApi.availability}`, params, true, true, false).pipe(
      map(response => response.data)
    );

  }
  
}
