import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings } from '@app/core/interfaces/admin.interface';
import { PurchaseApiService, IAreaAvailabilityParams } from '@app/shared/services/purchase-api.service';

@Component({
  selector: 'app-map-point-select',
  templateUrl: './map-point-select.component.html',
  styleUrls: ['./map-point-select.component.scss']
})
export class MapPointSelectComponent {

  @Input() set idFamily(newValue: any) {

    console.log("ID: ", newValue)

    if (newValue) {
      this.loadArea(newValue);
    }

  }

  @Input() set date(newValue: Date) {

    this._date = newValue;
    this.loadPointsAvaiability();

  }

  @Input() pointSelected: MultiselectItem[] = [];
  @Output() pointSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();
  @Input() set singleSelection(newValue: boolean) {

    if (newValue != null) {
      this.multiselectSettings_base.singleSelection = newValue;
    }
    
  }

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  pointsList: MultiselectItem[] = [];

  area: any;
  _date: Date;

  constructor(private purchaseApiService: PurchaseApiService) { }

  ngOnInit() { }

  loadArea(familyId) {

    this.purchaseApiService.allArea().subscribe(areas => {

      this.area = areas.find(area => area.idProductFamily == familyId);
      this.loadPointsAvaiability();

    })

  }

  loadPointsAvaiability() {

    if (this.area && this._date) {

      const params: IAreaAvailabilityParams = {
        idArea: this.area?.id,
        date: this._date
      }

      this.purchaseApiService.areaAvailability(params).subscribe(area => {

        this.pointsList = area?.spotList.map(point => {
          return {
            id: point.code,
            itemName: point.code
          } as MultiselectItem
        })

      })

    }

  }

  onPointSelect(): void {
    this.pointSelectedChange.emit(this.pointSelected);
    this.onSelected.emit(this.pointSelected);
  }

}
