

<div class="d-flex justify-content-center">
  <div class="d-flex flex-column justify-content-center">
    <div class="d-flex justify-content-center">
      <div class="command-btn" (click)="onZoomOutClicked()">
        <i class="fas fa-search-minus"></i>
      </div>
    
      <div class="command-btn" (click)="onResetViewClicked()">
        <i class="fas fa-home"></i>
      </div>
      
      <div class="command-btn" (click)="onZoomInClicked()">
        <i class="fas fa-search-plus"></i>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center"></div>
    <div class="d-flex justify-content-between">

      <div class="command-btn" (click)="onPanLeft100Clicked()">
        <i class="fas fa-arrow-left"></i>
      </div>

      <div class="command-btn" (click)="onPanUp100Clicked()">
        <i class="fas fa-arrow-up"></i>
      </div>
      <div class="command-btn" (click)="onPanDown100Clicked()">
        <i class="fas fa-arrow-down"></i>
      </div>
      <div class="command-btn" (click)="onPanRight100Clicked()">
        <i class="fas fa-arrow-right"></i>
      </div>

    </div>
    <div class="justify-content-center d-none d-md-flex">


    </div>
  </div>
</div>
<div class="w-100" style="height: 450px; position: relative;">
  <div class="" style="position: absolute; top: 0px; bottom: 0; left: 0; right: 0;">

    <pan-zoom [config]="panZoomConfig">
  
      <div style="position: relative;">
  
        <div class="image" [style.background-image]="'url(' + mapUrl + ')'">

          <!--<div class="point {{ class }}" (click)="pop.show()" [style.left.px]="point.x - offset" [style.top.px]="point.y - offset" *ngFor="let point of points; let i = index"></div>-->
          <map-point [style.left.px]="point.x - offset" [style.top.px]="point.y - offset" *ngFor="let point of points; let i = index" [(point)]="points[i]" (pointSelected)="addPointToCart($event)" (pointDeselected)="removePointFromCart($event)" [selectable]="selectable"></map-point>

        </div>
  
        <!--<div class="image image-phone d-block d-md-none">
          <div class="point {{ class }}" (click)="selected('ciao')"></div>
        </div>-->
  
      </div>
  
    </pan-zoom>
  </div>
</div>


 




<!--

      <div class="container">
        <div class="image">
          <div class="point {{ class }}" (click)="selected('ciao')"></div>
        </div>
      </div>

-->