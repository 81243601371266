import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import { getEndPointWithParams } from '../../../assets/js/util.js';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { RequestBody } from '../interfaces/admin.interface';
import { PriceAction, Price } from '../interfaces/price.interface';


@Injectable()
export class PriceService {

  typeAction: PriceAction;

  priceListNameList: string[];


  constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) { }


  priceList(reqBody: RequestBody): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.listPrice_all);
    return this.post(endPoint, reqBody);
  }

  priceProductList(idPriceList: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_priceList, idPriceList);
    return this.get(endPoint);
  }

  getPriceListInfo(idPriceList: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.listPrice_infoIdPriceList, idPriceList);
    return this.get(endPoint);
  }

  getAssociatedReseller(idPriceList: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.associated_reseller, idPriceList);
    return this.get(endPoint);
  }

  createPriceList(priceListInfo: Price): Observable<any> {
    const endPoint = ENDPOINT.listPrice_create;
    return this.post(endPoint, priceListInfo);
  }

  updatePriceList(priceListInfo: Price): Observable<any> {
    const endPoint = ENDPOINT.listPrice_update;
    return this.put(endPoint, priceListInfo);
  }

  deactivePriceList(idPriceList: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.listPrice_actionBlock, idPriceList);
    return this.get(endPoint);
  }

  activePriceList(idPriceList: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.listPrice_actionActive, idPriceList);
    return this.get(endPoint);
  }

  deactivePriceListProduct(idPriceList: number, idProduct: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.listPricePrice_actionBlock, idPriceList, idProduct);
    return this.get(endPoint);
  }

  activePriceListProduct(idPriceList: number, idProduct: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.listPricePrice_actionActive, idPriceList, idProduct);
    return this.get(endPoint);
  }

  duplicatePriceList(idPriceList: number): Observable<any> {
    const endPoint = ENDPOINT.listPrice_duplicate;
    return this.post(endPoint, { "idPriceList": idPriceList });
  }

  setTypeAction(typeAction: PriceAction): void {
    this.typeAction = typeAction;
  }

  getTypeAction(): PriceAction {
    return this.typeAction;
  }

  setPriceListNameList(priceList: Price[]): void {
    this.priceListNameList = [];
    priceList.forEach(el => this.priceListNameList.push(el.priceListName));
  }
  
  getPriceListNameList(): string[] {
    return this.priceListNameList;
  }



  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }


  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }
}



