// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  restBaseUrl: 'https://testapi-aquamore.soluzione1.it/aquamore-rest/rest/webapp',
  ecommBaseUrl: 'https://testapi-aquamore.soluzione1.it/aquamore-rest/rest/ecommerce',
  rows: 10,
  marketPlace: "https://testweb-aquamore.soluzione1.it/",
  cdnImagesUrl: 'https://testcdn.soluzione1.it/aquamore/images/',
  version: "2.1.0 T (Testing)"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

