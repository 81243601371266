import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PortalService } from '../../../core/services/portal.service';
import { Multiselect, MultiselectItem, MultiselectSettings } from '../../../core/interfaces/admin.interface';

@Component({
  selector: 'aquamore-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.scss']
})
export class CustomerSelectComponent implements OnInit {

  @Input() customerSelected: MultiselectItem[] = [];

  @Output() customerSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: false,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false
  };

  customerList: MultiselectItem[] = [];

  constructor(public portalService: PortalService) { 

    this.load();

  }

  ngOnInit(): void { }

  onCustomerSelect(): void {
    this.customerSelectedChange.emit(this.customerSelected);
    this.onSelected.emit(this.customerSelected);
  }

  load() {

    this.portalService.getMultiselectItems(Multiselect.CUSTOMER_EMAILS).subscribe((response) => {

      this.customerList = response?.data.map(customer => {

        return {
          id: customer.id,
          itemName: customer.name
        } as MultiselectItem

      })

    },
      (error) => {
        console.log("errore", error);
      });

  }

}
