import { Component, Input } from '@angular/core';
import { Product, TranslationAttributeKey } from '../../../core/interfaces/product.interface';
import { CartService } from '../../../core/services/cart.service';
import { CommonService } from '../../../shared/services/common.service';

@Component({
  selector: 'aquamore-product-sales-header-section',
  templateUrl: './product-sales-header-section.component.html',
  styleUrls: ['./product-sales-header-section.component.scss']
})
export class ProductSalesHeaderSectionComponent {

  @Input() set product(newValue: Product) {
    this._product = newValue;
    this.loadProductImage();
    this.setupTranslationAttribute();
  };

  _product: Product;

  productImg: string;
  productName: string;
  productShortDescription: string;
  productDescription: string;

  constructor(private commonService: CommonService, private cartService: CartService) {
    
  }

  loadProductImage() {
    this.productImg = this.commonService.getProductLogo(this._product);
  }

  setupTranslationAttribute(): void {
    this.productName = this.cartService.getTranslationAttributeValue(TranslationAttributeKey.NAME, this._product);
    this.productShortDescription = this.cartService.getTranslationAttributeValue(TranslationAttributeKey.SHORT_DESCRIPTION, this._product);
    this.productDescription = this.cartService.getTranslationAttributeValue(TranslationAttributeKey.DESCRIPTION, this._product);
  }

}
