import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings, Multiselect } from '../../../core/interfaces/admin.interface';
import { PortalService } from '../../../core/services/portal.service';

@Component({
  selector: 'aquamore-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss']
})
export class ProductSelectComponent implements OnInit {

  @Input() set preselectedProducts(newValue: number[]) {
    this.selectFromId(newValue);
  }

  @Input() set idPriceList(newValue: number) {
    if (newValue) {
      this.load(newValue);
    }
    this._idPriceList = newValue;
  }

  @Input() set singleSelection(newValue: boolean) {
    this.multiselectSettings_base.singleSelection = !!newValue;
    this.multiselectSettings_base = { ... this.multiselectSettings_base };
  }

  @Input() set disabled(newValue: boolean) {  
    this.multiselectSettings_base.disabled = !!newValue;
    this.multiselectSettings_base = { ... this.multiselectSettings_base };
  }

  @Input() productsSelected: MultiselectItem[] = [];

  @Output() productsSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  _idPriceList: number;

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: false,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  list: MultiselectItem[] = [];

  preselectedIds: number[];

  constructor(public portalService: PortalService) { }

  ngOnInit(): void { 
    this.load(this._idPriceList);
  }

  onElementSelect(): void {
    this.productsSelectedChange.emit(this.productsSelected);
    this.onSelected.emit(this.productsSelected);
  }

  load(idPriceList: number = null) {

    let $api;

    if (idPriceList) {
      console.log("QUI PRIC2, ", idPriceList)
      $api = this.portalService.getMultiselectItemsWithParam(Multiselect.PRODUCTS, idPriceList);
    } else {
      console.log("SENZA, ")
      $api = this.portalService.getMultiselectItems(Multiselect.PRODUCTS);
    }

    $api.subscribe((response) => {

      this.list = response?.data.map(product => {

        return {
          id: product.id,
          itemName: product.name
        } as MultiselectItem

      })

      if (this.preselectedIds?.length && this.productsSelected.length == 0) {
        this.selectFromId(this.preselectedIds);
      }

    },
    (error) => {
      console.log("errore", error);
    });

  }

  selectFromId(ids: number[], withEvent: boolean = true) {

    this.preselectedIds = ids;

    this.productsSelected = this.list.filter(c => ids?.includes(c.id));

    if (withEvent) {
      this.onElementSelect();
    }

  }

}
