import { Customer } from './customer.interface';
import { AccessChannel } from './product.interface';
export enum State {
  ALL = null,
  ACTIVE = 1,
  INACTIVE = 0
}

export const StateMultiselectItem: { [key in string] : MultiselectItem } = {
  'active': { id: State.ACTIVE, itemName: 'Active' },
  'inactive': { id: State.INACTIVE, itemName: 'In Active' }
}
export interface RadioStateTranslation {
  key: State;
  translationKey: string;
}

export enum Multiselect {
  EMAIL = "email",
  ROLE = "role",
  ROLE_LIST = "roleList",
  COMPANY = "company",
  USER = "user",
  RESELLER = "reseller",
  RESELLER_OWN = "resellerOwn",
  RESELLER_IS_COMPANY = "resellerIsCompany",
  RESELLER_PRICE_LIST = "resellerPriceList",
  PRODUCTS = "products",
  PRODUCT_FAMILY = "productFamily",
  PRODUCT_TYPE = "productType",
  PRODUCT_CODE = "productCode",
  PRICE_LIST = "priceList",
  PRICE_LIST_RESELLER_IS_COMPANY = "priceListResellerIsCompany",
  DELIVERY_AREA = "deliveryArea",
  AVAILABILITY_PERIOD = "availabilityPeriod",
  STATE = "state",
  UDM = "udm",
  WEEK_DAY = "weekDay",
  CURRENCY = "currency",
  TRANSLATION_ATTRIBUTE = "translationAttribute",
  CHANNEL_LIST = "channelList",
  OBLITERATION_ORDER_CODE = "obliterationOrderCode",
  CUSTOMER = "customer",
  ORDER_CODE = "orderCode",
  ORDER_STATE = "orderState",
  PAYMENT_STATE = "paymentState",
  OBLITERATION_STATE = "obliterationState",
  SALE_CODE = "saleCode",
  EXTERNAL_API = "externalApi",
  ENTITY = "entity",
  OWNERS = "owners",
  ORDERS = "orders",
  CUSTOMER_PRIVACY = "customer.columns.privacy",
  CUSTOMER_GENERAL_SALE = "customer.columns.general_sale",
  CUSTOMER_ALL_ARTICLES = "customer.columns.all_articles",
  CUSTOMER_NEWS = "customer.columns.news",
  CUSTOMER_PROFILE = "customer.columns.profile",
  CUSTOMER_SENSIBLE = "customer.columns.sensible_data",
  ACCESS_CHANNEL_RESPONSE = "accessChannelResponse",
  PAYMENT_TYPES = "payment_bo_types",
  RESELLER_USER = "resellerUser",
  PRODUCTNAME = "productname",
  CUSTOMER_EMAILS = "customer_emails"
}


export interface MultiselectSettings {
  singleSelection: boolean;
  text: string;
  searchPlaceholderText: string;
  selectAllText?: string;
  unSelectAllText?: string;
  enableSearchFilter: boolean;
  classes: string;
  badgeShowLimit?: number;
  disabled?: boolean;
  lazyLoading?: boolean;
  labelKey?: string;
  enableCheckAll?: boolean;
  position?: string;
  autoPosition?: boolean;
  tagToBody?: boolean;
  showCheckbox?: boolean;
}

// Value key MultiselectItems like enum
export interface MultiselectItems {
  currency?: MultiselectItem[];
  email?: MultiselectItem[];
  role?: MultiselectItem[];
  roleList?: MultiselectItem[];
  company?: MultiselectItem[];
  user?: MultiselectItem[];
  reseller?: MultiselectItem[];
  resellerOwn?: MultiselectItem[];
  products?: MultiselectItem[];
  productFamily?: MultiselectItem[];
  productType?: MultiselectItem[];
  productCode?: MultiselectItem[];
  deliveryArea?: MultiselectItem[];
  availabilityPeriod?: MultiselectItem[];
  state?: MultiselectItem[];
  languageCode?: MultiselectItem[];
  udm?: MultiselectItem[];
  weekDay?: MultiselectItem[];
  accessChannel?: MultiselectItem[];
  resellerIsCompany?: MultiselectItem[];
  priceListResellerIsCompany?: MultiselectItem[];
  customer?: MultiselectItem[];
  orderCode?: MultiselectItem[];
  orderState?: MultiselectItem[];
  obliterationState?: MultiselectItem[];
  paymentState?: MultiselectItem[];
  saleCode?: MultiselectItem[];
  externalApi?: MultiselectItem[];
  entity?: MultiselectItem[];
  owners?: MultiselectItem[];
  customer_check?: CustomerCheckMultiselect;
  accessChannelResponse?: MultiselectItem[];
  resellerUser?: MultiselectItem[];
  // metadata
  delivery?: MultiselectItem[];
  theme?: MultiselectItem[];
  resellerPriceList?: MultiselectItem[];
  priceList?: MultiselectItem[];
  validityTime?: MultiselectItem[];
  usability?: MultiselectItem[];
  withdrawalList?: MultiselectItem[];
  province?: MultiselectItem[];
  city?: MultiselectItem[];
  vipCardType?: MultiselectItem[];
  bgyTopType?: MultiselectItem[];
  translationAttribute?: MultiselectItem[];
  vatCountryCode?: MultiselectItem[];
  vatNatura?: MultiselectItem[];
  ticketType?: MultiselectItem[];
  externalTicketType?: MultiselectItem[];
  channelList?: MultiselectItem[];
  payment_bo_types?: MultiselectItems[];
  productname?: MultiselectItem[];
  mailingList?: MultiselectItem[];
  faqTypes?: MultiselectItem[];
}

export interface MultiselectItem {
  id: any;
  itemName: string;
}

export interface LoggedUserInfo {
  token: string;
  name: string;
  surname: string;
  enabled: boolean;
  role: string;
}

export interface RequestBody {
  paging: boolean;
  page: number;
  rows: number;
  summary?: boolean;
  orderBy?: string;
  direction?: string;
  filters: EntityEnum;
  insideValidity?: boolean;
}

export interface EntityEnum {
  ORDER?: any;
  ORDER_VIEW?: any;
  PRICE_LIST_VIEW?: any;
  COMPANY?: any;
  RESELLER?: any;
  RESELLER_DELIVERY_AREA?: any;
  RESELLER_AVAILABILITY?: any;
  RESELLER_AVAILABILITY_DAY?: any;
  RESELLER_AVAILABILITY_DAY_TIME?: any;
  CUSTOMER?: any;
  PRODUCT?: any;
  LICENSE?: any;
  PRICE_LIST?: any;
  PRODUCT_TYPE?: any;
  PRODUCT_FAMILY?: any;
  USER?: any;
  ROLE?: any;
  TRANSLATION_ATTRIBUTE?: any;
  TICKET?: any;
  OBLITERATIONS?: any;
  SALE?: any;
  STATE?: any;
  ENTITY?: any;
  OWNER?: any;
  CUSTOMER_CHECK?: any;
  FAQ_HEADER?: any;
  FAQ_DETAIL?: any;
  COUPON?: any;
}

export interface SweetAlertMsg {
  loadingText?: string;
  creationText?: string;
  updatingText?: string;
  errorText?: string;
  firstError?: string;
  secondError?: string;
  warning?: string;
}

export interface CustomerCheckMultiselect {
  privacy?: MultiselectItem[];
  general_sale?: MultiselectItem[];
  all_articles?: MultiselectItem[];
  news?: MultiselectItem[];
  profile?: MultiselectItem[];
  sensible?: MultiselectItem[];
}