<div class="form-group">
  <label for="prod-m">{{ (multiselectSettings_base.singleSelection ? 'form.family' : 'form.families')| translate }}</label>
  <angular2-multiselect [data]="list"
      [(ngModel)]="familiesSelected"
      [settings]="multiselectSettings_base"
      (onSelect)="onElementSelect()"
      (onSelectAll)="onElementSelect()"
      (onDeSelectAll)="onElementSelect()"
      (onFilterSelectAll)="onElementSelect()"
      (onFilterDeSelectAll)="onElementSelect()">
  </angular2-multiselect>
</div>