<div class="filters p-4">
    <div class="filter-text">{{'common.filters_txt' | translate}}</div>
    <div class="filters-container">
        <ng-content></ng-content>
        <div *ngIf="radioSection" class="row filter-row mt-4 justify-content-between">
            <div class="wrapper col-lg-3 align-self-end">
                <ng-content select="app-radio-state"></ng-content>
                <ng-content select=".checkbox_block"></ng-content>
            </div>
            <div class="wrapper col-lg-6">
                <ng-content select="[accessChannelResponse]"></ng-content>
            </div>
            <div class="wrapper col-lg-4">
                <ng-content select="[orderSelector]"></ng-content>
            </div>
            <div class="wrapper col-lg-2">
                <ng-content select="[commisionSelector]"></ng-content>
            </div>
        </div>

        <div class="row filter-row mt-4 justify-content-between">

            <div class="col-lg-6 d-flex align-items-center">
                <ng-content select="[download_export]"></ng-content>
            </div>

            <div class="filter-btn col-lg-6 text-right">
                <button class="btn btn-search mr-2"
                        (click)="searchFilter()">
                    <strong>{{'common.Search_by_filters' | translate}}</strong>
                </button>
                <button class="btn btn-danger"
                        (click)="onresetFilters()">
                    <strong>{{'common.reset_filters' | translate}}</strong>
                </button>
            </div>

        </div>
        
    </div>
</div>
