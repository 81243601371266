import { CustomerAction } from './../interfaces/customer.interface';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import { getEndPointWithParams, dateFormatting } from '../../../assets/js/util.js';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { RequestBody } from '../interfaces/admin.interface';
import { saveAs } from 'file-saver/src/FileSaver.js';


@Injectable()
export class CustomerService {

  typeAction: CustomerAction;



  constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) { }


  customerList(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.listCustomer_all;
    return this.post(endPoint, reqBody);
  }

  customerOrderList(idCustomer: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.listOrder_allCustomer, idCustomer);
    return this.get(endPoint);
  }


  setTypeAction(typeAction: CustomerAction): void {
    this.typeAction = typeAction;
  }

  getTypeAction(): CustomerAction {
    return this.typeAction;
  }

  blockCustomer(idCustomer: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.listCustomer_actionBlock, idCustomer);
    return this.get(endPoint);
  }

  activeCustomer(idCustomer: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.listCustomer_actionActive, idCustomer);
    return this.get(endPoint);
  }

  downloadCustomerExport(reqBody: RequestBody) {
    const endPoint = ENDPOINT.listCustomer_allExport;
    return this.post_downloadFile(endPoint, reqBody)
  }




  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  post_downloadFile(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
        this.restful.post_downloadFile(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
            this.responseHandler_downloadFile(response, observer);
        },error =>{
            observer.error("download file error");
        });
    });
}


  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }

  responseHandler_downloadFile(response: HttpResponse<any>, observer: any): void {
    saveAs(response, "customers_"+dateFormatting(new Date())+".xls");
    observer.next(response);
  }

}



