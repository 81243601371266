import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectItem, MultiselectSettings, Multiselect } from '../../../core/interfaces/admin.interface';
import { PortalService } from '../../../core/services/portal.service';

export enum TranslationAttributePrefix {
  all = "",
  image = "IMG_",
  imageFamily = "IMG_FAMILY", // Ok senza _ finale
  pdf = "PDF_",
  pdfImgProduct = "PDF_IMG_PRODUCT",
  pdfImgFamily = "PDF_IMG_FAMILY"
}

@Component({
  selector: 'aquamore-translation-attribute-select',
  templateUrl: './translation-attribute-select.component.html',
  styleUrls: ['./translation-attribute-select.component.scss']
})
export class TranslationAttributeSelectComponent implements OnInit {

  @Input() set prefixAttributes(newValue: TranslationAttributePrefix[]) {
    this._prefixAttributes = newValue;
    this.loadMultiselectItems();
  }

  @Input() excludedAttributes: string[];
  @Input() attributeSelected: MultiselectItem[] = [];

  @Output() attributeSelectedChange = new EventEmitter< MultiselectItem[]>();
  @Output() onSelected = new EventEmitter< MultiselectItem[]>();

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: "Select",
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };

  attributeList: MultiselectItem[] = [];

  private _prefixAttributes: TranslationAttributePrefix[] = [TranslationAttributePrefix.all];

  constructor(private portalService: PortalService) { }

  ngOnInit() {
    this.loadMultiselectItems();
  }

  loadMultiselectItems(): void {
   
    this.portalService.getMultiselectItems(Multiselect.TRANSLATION_ATTRIBUTE).subscribe((response) => {
      const { data } = response;

      const allAttributes = this.portalService.getItemsFromDataNoId(data);

      let itemsAllowed: MultiselectItem[] = [];

      this._prefixAttributes.forEach(prefix => {

        const filtered = allAttributes.filter(att => {

          if (prefix == TranslationAttributePrefix.image) {
            return att.itemName.startsWith(prefix);
          } else {
            return att.itemName.startsWith(prefix);
          }
        
        });

        itemsAllowed = itemsAllowed.concat(filtered);

      })

      this.attributeList = itemsAllowed;

      if (this.excludedAttributes && this.excludedAttributes.length) {
        this.removeExcludedItems();
      }
 
    });

  }

  /** Removal of unrequired prefixes from attributeList select */
  removeExcludedItems(): void {
    this.attributeList = this.attributeList.filter(a => !(this.excludedAttributes.findIndex(ex => a.itemName.includes(ex)) >= 0));
  }

  onAttributeSelect(): void {
    this.attributeSelectedChange.emit(this.attributeSelected);
    this.onSelected.emit(this.attributeSelected);
  }

}
