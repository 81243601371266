<div class="modal-header d-flex align-items-center justify-content-start header flex-wrap flex-md-nowrap ">
  <div class="d-flex flex-column align-items-start">
    <div class="modal-title mb-2 ml-4">{{ (familyImage ? 'image_section.image' : 'image_section.media') | translate }}</div>
  </div>
</div>

<div class="modal-body">
  <div *ngIf="media && media.value">
    <a *ngIf="attributeSelected[0].itemName.startsWith(translationAttributePrefix.pdf && !translationAttributePrefix.pdfImgFamily)" [href]="media.value" target="_blank">
      <div class="w-100 text-center cursor-pointer">
        <i class="fas fa-file-pdf fa-4x text-danger"></i>
      </div>
    </a>
    <div class="w-100 text-center" *ngIf="attributeSelected[0].itemName.startsWith(translationAttributePrefix.image || media.key[0].itemName.startsWith(translationAttributePrefix.pdfImgProduct))">
      <img src="{{ media.value }}" class="image-preview"/>
    </div>
  </div>
  
  <aquamore-language-select [(languageSelected)]="languageSelected"></aquamore-language-select>
  
  <aquamore-translation-attribute-select
    [(attributeSelected)]="attributeSelected"
    [prefixAttributes]="prefixAttributes"
    [excludedAttributes]="excludedAttributes"
    (attributeSelectedChange)="filterFilesAccepted($event)">
  </aquamore-translation-attribute-select>
  
  <s1-input-file [filesAccepted]="typesAccepted"
    (filesizeError)="manageFilesizeError()" (onFileLoaded)="fileLoaded($event)">
  </s1-input-file><!-- [s1FileType.image, s1FileType.pdf] -->
  
  <div class="w-100 text-center" *ngIf="fileSelected">
    <img class="image-preview mt-5" *ngIf="attributeSelected[0].itemName.startsWith(translationAttributePrefix.image)" [src]="domSanitizer.bypassSecurityTrustUrl('data:image/jpg;base64,'+fileSelected.fileData)" />
  </div>
</div>

<div class="modal-footer d-flex justify-content-between flex-row flex-wrap">
  <div>
    <button type="button" (click)="close()" class="btn-sm btn-cancel mr-3">{{ 'form.discard' | translate | uppercase }}</button>
    <button class="btn btn-action" (click)="upload()" [disabled]="!canUpload" type="button">{{ 'form.upload' | translate | uppercase }}</button>
  </div>
</div>
