import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import { getEndPointWithParams } from '../../../assets/js/util.js';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { RequestBody } from '../interfaces/admin.interface';
import { UserAction, UserInfo } from '../interfaces/user.interface';
import { S1HttpClientService } from '@app/s1/services/s1-http-client.service';

enum UserAPI {
  list = "/list",
  userSessionInfo = "/user_session/info",
  userSessionUpdate = "/user_session/update",
  changePassword = "/account/changepwd",
  invite = "/invite",
  privilege = "/privilege",
  enable = "/enable"
}

@Injectable()
export class UserService {

  path = "/admin/user";

  typeActionUser: UserAction;
  syncUserProfileInfo = new BehaviorSubject<UserInfo>({});

  constructor(private logger: LoggingService, private settings: SettingsService,
    private restful: RestfulService, private authService: AuthService, private s1HttpClient: S1HttpClientService) {

      this.authService.syncOnLoginEvent().subscribe(userIsLogged => {
        if (userIsLogged) {
          this.getUserInfo().subscribe(res => {
            const { outcome, data } = res;
            if (outcome.success) {
              this.syncUserInfo(data);
            }
          });
        }
      });
  }

  setTypeActionUser(typeActionUser: UserAction): void {
    this.typeActionUser = typeActionUser;
  }

  getTypeActionUser(): UserAction {
    return this.typeActionUser;
  }

  /* UTILS PER MANTENERE AGGIORNATA SIDEBAR */
  syncUserInfo(userInfo: UserInfo) {
    this.syncUserProfileInfo.next(userInfo);
  }
  getSyncUserInfo(): Observable<UserInfo> {
    return this.syncUserProfileInfo.asObservable();
  }

  usersList(reqBody: RequestBody): Observable<any> {
    return this.s1HttpClient.post(`${this.path}${UserAPI.list}`, reqBody);
  }

  getUserInfoById(idUser: string): Observable<any> {
    return this.s1HttpClient.get(`${this.path}/${idUser}`);
  }

  getUserInfo(): Observable<any> {
    return this.s1HttpClient.get(`${this.path}${UserAPI.userSessionInfo}`);
  }

  getIdResellerSelected(): string {
    return this.authService.getIdResellerChoosed();
  }

  deleteUser(idUser: string): Observable<any> {
    return this.s1HttpClient.delete(`${this.path}/${idUser}`);
  }

  createUser(reqBody: UserInfo): Observable<any> {
    return this.s1HttpClient.post(`${this.path}`, reqBody);
  }

  editUserById(idUser: string, reqBody: UserInfo): Observable<any> {
    return this.s1HttpClient.put(`${this.path}/${idUser}`, reqBody);
  }

  editUser(reqBody: UserInfo): Observable<any> {
    return this.s1HttpClient.put(`${this.path}${UserAPI.userSessionUpdate}`, reqBody);
  }

  editPassword(reqBody: any): Observable<any> {
    return this.s1HttpClient.put(`${UserAPI.changePassword}`, reqBody); // E' corretto senza this.path
  }

  invite(idUser: number): Observable<any> {
    return this.s1HttpClient.put(`${this.path}${UserAPI.invite}/${idUser}`, {});
  }

  getUserPrivilige(): Observable<any> {
    return this.s1HttpClient.get(`${this.path}${UserAPI.privilege}`);
  }

  enableUser(idUser: number): Observable<any> {
    return this.s1HttpClient.put(`${this.path}${UserAPI.enable}/${idUser}`, {});
  }

  disableUser(idUser: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.admin_user_disable, idUser);
    return this.put(endPoint, null);
  }


  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }


  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }
}



