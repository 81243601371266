import { MultiselectItem } from './admin.interface';
import { CustomField } from './product-sale.interface';

export enum ProductAction {
  NEW_PRODUCT = "newProduct",
  EDIT_REGISTRY = "editRegistry",
  EDIT_THRESHOLDS = "editThresholds"
}
export enum FamilyAction {
  NEW = "new",
  EDIT = "edit",
  DELETE = "delete",
  ACTIVATE = "activate",
  DEACTIVATE = "deactivate"
}

export enum AttributeAction {
  NEW = "new",
  EDIT = "edit",
  DISABLE = "disable"
}

export enum LanguageCode {
  IT = "it",
  EN = "en"
}

export enum VatCountryCode {
  IT = "IT",
  EU = "EU",
  EE = "EE"
}

//https://www.danea.it/blog/fattura-elettronica-nuove-codifiche-per-documenti-e-natura-iva/
export enum VatNatura {
  N1 = "N1",
  N2 = "N2",
  N21 = "N2.1",
  N22 = "N2.2",
  N3 = "N3",
  N31 = "N3.1",
  N32 = "N3.2",
  N33 = "N3.3",
  N34 = "N3.4",
  N35 = "N3.5",
  N36 = "N3.6",
  N4 = "N4",
  N5 = "N5",
  N6 = "N6",
  N69 = "N6.9",
  N7 = "N7"
}

export enum TicketType {
  QR_CODE = "QR_CODE",
  BARCODE = "BARCODE"
}

export enum ExternalTicketType {
  QR_CODE = "QR_CODE",
  BARCODE = "BARCODE",
  PDF417 = 'PDF417'
}

export enum Usability {
  END_DAY = "END_DAY",
  END_VALIDITY = "END_VALIDITY",
  NUM = "NUM"
}

export enum ValidityTime {
  CUSTOM = "CUSTOM",
  ZERO_DAY = "0DAY",
  ONE_DAY = "1DAY",
  ONE_DAY_SPEC = "1DAY_SPEC",
  ONE_MONTH = "1MONTH",
  ONE_YEAR = "1YEAR",
}

export enum Withdrawal {
  AIRPORT = "AIRPORT",
  HOME = "HOME"
}

export enum WithdrawalOnly {
  HOME = "HOME"
}

export enum VipCardWithdrawal {
  AIRPORT = "AIRPORT",
  HOME = "HOME"
}

export enum UnitOfMeasure {
  DAY = "gg",
  NR = "n°",
  PERC = "%"
}

export enum ExternalApi {
  NESSUNO = "NESSUNO",
  GIS = "GIS",
  MOMA = "MOMA"
}


export enum VipCardType {
  NOMINAL_STANDARD = "NOMINAL_STANDARD",
  NOMINAL_PLUS = "NOMINAL_PLUS",
  COMPANY_STANDARD = "COMPANY_STANDARD",
  COMPANY_PLUS = "COMPANY_PLUS",
}

export enum BgyTopType {
  BGY_TOP = "BGY_TOP"
}

export enum IdentificationNameGrouped {
  FAST_TRACK = "Fast Track",
  VIP_LOUNGE = "Check-in Lounge",
  VIP_CARD = "Vip-Card",
  BGY_TOP = "BGY Top",
  BGY_TOP_A_R = "BGY Top - Andata + Ritorno",
  BGY_TOP_O_N = "BGY Top - Orario Notturno",
  FAST_TRACK_PLUS = "Fast Track",
  HELLO_SKY_LOUNGE = "HelloSky Lounge",
  HELLO_SKY_LOUNGE_DOCCIA = "HelloSky Lounge + Doccia",
  BATT_VOLO = "Battesimo del volo"
}


export enum CodeFamily {
  FAST_TRACK = "FAST_TRACK",
  VIP_LOUNGE = "VIP_LOUNGE",
  VIP_CARD = "VIP_CARD",
  HONOR_CARD = "HONOR_CARD",
  BGY_TOP = "BGY_TOP",
  BATT_VOLO = "BATT_VOLO",
  FAST_TRACK_PLUS = "FAST_TRACK_PLUS",
  VIP_LOUNGE_HELLOSKY_LOUNGE = "VIP_LOUNGE_HELLOSKY_LOUNGE",
  VIP_LOUNGE_HELLOSKY_LOUNGE_DOCCIA = "VIP_LOUNGE_HELLOSKY_LOUNGE_DOCCIA",
  COMBO = "COMBO",
  PARK = "PARK",
  PORTERAGGIO = "PORTERAGGIO"
}

export enum CodeFamilyNum {
  FAST_TRACK = 1,
  VIP_LOUNGE = 2,
  VIP_CARD = 3,
  BGY_TOP = 4,
  FAST_TRACK_PLUS = 5,
  BATT_VOLO = 6,
  COMBO = 7
}


export enum ProductType {
  SERVICE_ = 2,
  CONDITION = 3,
  SW_MODULE = 4,
  MONTLY_FEE = 1,
  ANNUAL_FEE = 5
}

export enum ProductType {
  STANDARD = 1,
  PLACE = 2,
  SERVICE = 3,
  // BATT_VOLO = 4,
  // COMBO = 5,
  // PARK = 6,
  // SEA = 9,
  // PORTERAGGIO = 10
}

export enum TranslationAttributeKey {
  DESCRIPTION = "DESCRIPTION",
  SHORT_DESCRIPTION = "SHORT_DESCRIPTION",
  NAME = "NAME"
}

export enum WeekDayAsNumber {
  LUNEDI = 1,
  MARTEDI = 2,
  MERCOLEDI = 3,
  GIOVEDI = 4,
  VENERDI = 5,
  SABATO = 6,
  DOMENICA = 0,
}

export enum AccessChannel {
  ACALL = "ACALL",
  FSTK = "FSTK",
  VLSCB = "VLSCB",
  VLGIS = "VLGIS"
}

export enum TranslationAttributeImageKey {
  IMG_PRODUCT = "IMG_PRODUCT",
  IMG_PRODUCT_LOGO = "IMG_PRODUCT_LOGO",
  IMG_FAMILY = "IMG_FAMILY",
  IMG_FAMILY_LOGO = "IMG_FAMILY_LOGO",
  IFRAMEVIDEO = "IFRAMEVIDEO",
  PDF_IMG_PRODUCT = "PDF_IMG_PRODUCT",
  PDF_IMG_FAMILY = "PDF_IMG_FAMILY"
}

export interface Product {
  idProduct: number;
  idProductFamily: number;
  idCompany: number;
  idType: number;
  companyName: string;
  typeName: string;
  familyName: string;
  productName: string;
  languageCode: string;
  itemCode: string;
  urlImg: string;
  unitOfMeasure: string;
  productState: number;
  regIva: string;
  priceListProductState?: number;
  idPriceListProduct?: number;
  isCombo?: boolean;
  singleProducts?: SingleProductInfo[];
  expanded?: boolean;
}

export interface ProductInfo {
  isCombo?: boolean;
  isExternal?: boolean;
  id?: number;
  idCompany?: number;
  idProductFamily: number;
  idProductType: number;
  itemCode: string;
  identificationName: string;
  urlImg: string;
  unitOfMeasure: string;
  state: number;
  regIva: string;
  translationAttribute?: TranslationAttribute[];
  thresholdAttribute?: ThresholdAttribute[];
  vatProduct?: VatProduct[];
  customFields?: CustomField;
}

export interface IProductDiscountInfo {
  idDiscount: number;
  isWithDiscount: boolean;
  discountAmount: number;
  discountName: string;
  discountType: string;
}

export interface ThresholdAttribute {
  id?: number;
  idProduct?: number;
  name: string;
  cumulative?: boolean;
  productThresholds: Threshold[];
  hasCarnet: boolean;
  carnet?: number;
}

export interface Threshold {
  id?: number;
  idPriceListProduct?: number;
  name: string;
  min: number;
  max: number;
  price?: any;
  resellerFee?: any;
}

export interface ProductTranslation {
  id?: number;
  name: string;
  description: string;
  shortDescription: string;
  languageCode: string;
}

export interface ProductsAnalysis {
  id: number;
  familyName: string;
  productType: string;
  user: string;
  ticket: string;
  resellerName: string;
  sellDate: number;
  state: number;
  detail?: ProductsAnalysisDetail[];
}

export interface ProductsAnalysisDetail {
  id: number;
  cardRetair?: string;
  city?: string;
  province?: string;
  address?: string;
  vipCardState?: VipCardState;
}

export enum VipCardState {
  ACTIVE,
  BLOCKED,
  INACTIVE,
  EXPIRED
}

export interface ProductFamily {
  id: number;
  name: string;
  idCompany?: number;
  codeFamily?: number;
  ticketType?: string;
  state: number;
  tsCreated?: number;
  tsLastModified?: number;
  imgUrl: string;
  urlWebsite: string;
  sequence?: number;
  translationAttribute: TranslationAttribute[];
}

export interface TranslationAttribute {
  id?: number;
  idProduct?: number;
  key?: any;
  description?: string;
  enabled?: boolean;
  languageCode?: any;
  value?: string;
}

export interface VatProduct {
  id?: number;
  country?: any;
  vatPersonal?: number;
  vatCompany?: number;
  customVatCodePersonal?: string;
  customVatCodeCompany?: string;
  natura?: any;
  desc?: string;
  default?: boolean;
}

export interface AccessChannelInfo {
  channelName: string;
  quantity?: number;
  checked?: boolean;
}

export interface SingleProductInfo {
  companyName: string
  familyName: string
  idCompany: number
  idProduct: number
  idProductFamily: number
  idType: number
  isCombo: boolean
  itemCode: string
  productName: string
  productState: number
  regIva: string
  typeName: string
  urlImg: string
}
