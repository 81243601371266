import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MultiselectItem } from '../../../core/interfaces/admin.interface';
import { TranslationAttribute } from '../../../core/interfaces/product-sale.interface';
import { ProductService } from '../../../core/services/product.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { TranslatorService } from '../../../core/translator/translator.service';
import { S1ButtonType } from '../../../s1/components/s1-button/s1-button.component';
import { S1UIService } from '../../../s1/services/s1-ui.service';
import { ImageUploadModalComponent } from '../image-upload-modal/image-upload-modal.component';
import { TranslationAttributePrefix } from '../translation-attribute-select/translation-attribute-select.component';

@Component({
  selector: 'aquamore-images-upload-section',
  templateUrl: './images-upload-section.component.html',
  styleUrls: ['./images-upload-section.component.scss']
})
export class ImagesUploadSectionComponent implements OnInit, AfterViewInit {

  @Input() idProduct: number;
  @Input() familyImage: boolean = false;
  @Input() prefixAttributes: TranslationAttributePrefix[] = [TranslationAttributePrefix.all];
  @Input() excludedAttributes: TranslationAttributePrefix[];

  @Input() set imageList(newValue: TranslationAttribute[]) {

    if (newValue) {
      this._mediaList = this.filterMediaList(newValue);
    }

  }

  _mediaList: TranslationAttribute[] = [];

  s1ButtonType = S1ButtonType;
  translationAttributePrefix = TranslationAttributePrefix;

  modalOption = {
    initialState: {
      idProduct: null,
      media: null,
      prefixAttributes: [TranslationAttributePrefix.all],
      excludedAttributes: [],
      familyImage: null
    },
    class: 'modal-lg custom-modal modal-dialog-centered',
    ignoreBackdropClick: true,
    id: null
  };

  bsModalRef: BsModalRef;

  private languagesList: MultiselectItem[] = [];

  constructor(
    private modalService: BsModalService,
    private translatorService: TranslatorService,
    private productService: ProductService,
    private ui: S1UIService,
    private settingService: SettingsService
  ) { 

    this.languagesList = this.translatorService.getAvailableLanguages().map(lan => {

      return {
        id: lan.code,
        itemName: lan.text
      } as MultiselectItem;

    });

  }
  ngAfterViewInit(): void {
    this.refreshImages();
  }

  ngOnInit() { }

  openUploadModal(image: TranslationAttribute = null) {

    this.modalOption.initialState.idProduct = this.idProduct;
    this.modalOption.initialState.media = image;
    this.modalOption.initialState.prefixAttributes = this.prefixAttributes;
    this.modalOption.initialState.excludedAttributes = this.excludedAttributes;
    this.modalOption.initialState.familyImage = this.familyImage;
    
    this.bsModalRef = this.modalService.show(ImageUploadModalComponent, this.modalOption);

    this.bsModalRef.content.updated$.subscribe((value) => {
      this.bsModalRef.hide();
      this.refreshImages(); 
    });

  }

  askDelete(image: TranslationAttribute) {

    this.ui.showDialogPopup("swal.media_ask_delete").then(accepted => {

      if (accepted.value) {

        this.productService.deleteProductImage(image.id, this.familyImage).subscribe(response => {

          if (response.outcome.success) {
            this.ui.showSuccessPopup("swal.media_deleted");
            this.refreshImages();
          }

        });

      }

    });

  }

  refreshImages() {

    this.productService.getProductById(this.idProduct, this.familyImage).subscribe(response => {

      if (response && response.data) {

        if (response.data.translationAttribute) {
          const temp = response.data.translationAttribute;

          temp.forEach(img => {

            img.languageCode = this.languagesList.filter(l => l.id === img.languageCode);
            img.key = [{id: null, itemName: img.key}];

          });

          this._mediaList = this.filterMediaList(temp);

        } else {
          this._mediaList = [];
        }

      }

    });

  }

  filterMediaList(list: any[]) {

    let mediaToShow: TranslationAttribute[] = [];

    this.prefixAttributes.forEach(prefix => {

      const filtered = list.filter(att => {

        const itemName = att.key[0].itemName;

        if (prefix == TranslationAttributePrefix.image) { // Soloper IMG bisogna controllare che non inizi per IMG_FAMILY
          return itemName.startsWith(prefix) && !itemName.startsWith(TranslationAttributePrefix.imageFamily);
        } else {
          return itemName.startsWith(prefix);
        }
      
      });

      mediaToShow = mediaToShow.concat(filtered);

    })

    return mediaToShow;

  }

}
