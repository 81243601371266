
<div class="card border rounded">

  <div class="card-body">
    
    <div class="card-text me-auto d-flex flex-column flex-lg-row w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="card-title h2 ">{{ _result.identificationName }}</div>
        <div class="card-subtitle h4 text-info">{{ (_result.customFieldsExternal?.uncoverd ? 'parking.uncovered' : 'parking.covered') | translate }}</div>
        <div class="row" *ngIf="!isParkFull()">
          <div class="col-auto pt-2">
            {{ 'parking.quantity' | translate }}
          </div>
          <angular2-multiselect class="qty-select"
            [data]="options"
            [settings]="multiselectSettings_Qty"
            [(ngModel)]="optionSelected"
            (onSelect)="selectParkAmount()"
            (onDeSelect)="deselectParkAmount()">
          </angular2-multiselect>
        </div> 
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column"
        *ngIf="!isParkFull(); else parkFull">
        <ng-container>
          <div class="h5 mb-2 text-danger" *ngIf="_result.customFieldsExternal?.feeFull != _result.customFieldsExternal?.feeCalculated"><s>{{ (_result.customFieldsExternal?.feeFull)| currency: "EUR" }}</s></div>
          <div class="h2 mb-2">{{ (_result.customFieldsExternal?.feeCalculated)| currency: "EUR" }}</div>
        </ng-container>
      </div>
    </div>
    
  </div>
  <div class="card-footer">
    <div class="pointer" (click)="openModal(modalTemplate)">
      <i class="fas fa-info icon"></i> {{ 'parking.info' | translate }}
    </div>
  </div>
</div>

<ng-template #modalTemplate>
  <div class="modal-header">
    <h4 id="dialog-static-name" class="modal-title pull-left">{{ _result?.customFieldsExternal?.shortProductDescription }}</h4>
    <button type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-description">
    <span [innerHTML]='_result?.customFieldsExternal?.productInformations'></span>
  </div>
</ng-template>

<ng-template #parkFull>
  <div class="park-full">
    <i class="far fa-calendar-times" aria-hidden="true"></i>
    <p>{{ 'base.not_available' | translate }}</p>
  </div>
</ng-template>