import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'aquamore-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit {

  @Input() htmlText: any;
  @Output() htmlTextChange = new EventEmitter<any>();

  summernoteConfig = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript']],
      ['fontsize', ['fontsize', 'color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['link','hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    followingToolbar: false
  }

  constructor() { }

  ngOnInit() { }

  cambia(ev: any) {
    this.htmlTextChange.emit(ev);
  }

}
