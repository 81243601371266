import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';

import swal, { SweetAlertResult } from 'sweetalert2';

export enum S1PopUpType {
  Success = "success",
  Warning = "warning",
  Error = "error",
  Info = "info",
  Question = "question"
}

@Injectable({
  providedIn: 'root'
})
export class S1UIService {

  private timerPopup = 2000;

  constructor(private translate: TranslateService, private toasterService: ToasterService) { }

  // Spinner
  showSpinner() {

    swal.fire({
      title: this.translate.instant('s1.swal.loading'),
      didOpen: function () {
          swal.showLoading();
      }
    });

  }

  closeSpinner() {
    swal.close();
  }

  // Popup
  showPopup(type: S1PopUpType, title?: string, text?: string, timer: number = this.timerPopup) {

    const titleTranslated = title ? this.translate.instant(title) : '';
    const textTranslated = text ? this.translate.instant(text) : '';

    return swal.fire({ title: titleTranslated, text: textTranslated, icon: type, timer: timer });

  }

  showSuccessPopup(text?: string, timer?: number) {
    return this.showPopup(S1PopUpType.Success, 's1.swal.success', text, timer);
  }
  
  showErrorPopup(text?: string, timer?: number) {
    return this.showPopup(S1PopUpType.Error, 's1.swal.error', text, timer);
  }

  showWarningPopup(text?: string, timer?: number) {
    return this.showPopup(S1PopUpType.Warning, 's1.swal.attention', text, timer);
  }

  showPopupNoPermission(timer?: number) {
    return this.showPopup(S1PopUpType.Warning, 's1.swal.attention',  's1.swal.noPermission', timer);
  }

  showHTTPErrorPopup(error: any) {
    
    let errorTitle = 's1.swal.error';
    let errorMessage = 'genericError';

    if (error.statusText) {
      errorMessage = this.translate.instant(error.statusText);
    }

    if (error.status) {

      errorTitle = "" + (error.status == 499 ? '' : error.status);

      switch (error.status) {
        case 401: // Unauthorized
        case 403: // Forbidden
          errorMessage = this.translate.instant('APIErrors.' + error.status);
          break;
      }

    }

    this.showErrorToast(errorMessage, errorTitle);
    //this.showPopup(S1PopUpType.Error, errorTitle, errorMessage);

  }

  showDialogPopup(title: string): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: this.translate.instant('s1.swal.ok'),
      cancelButtonText: this.translate.instant('s1.swal.cancel'),
    });

  }

  showInputPopup(title: string, text: string, fieldText: string, initialValue: string = null, 
    input: ('text' | 'email' | 'password' | 'number' | 'tel' | 'range' | 'textarea' 
    | 'select' | 'radio' | 'checkbox' | 'file' | 'url') = 'number'): Promise<any> {

    return swal.fire({
    title: this.translate.instant(title),
    text: text ? this.translate.instant(text) : '',
    input: input,
    inputValue: initialValue ? initialValue : '',
    inputPlaceholder: this.translate.instant(fieldText)
    });

  }

    // TOAST
    showSuccessToast(text: string) {
      this.toasterService.pop('success', this.translate.instant("s1.toast.success"), this.translate.instant(text));
      //this.toasterService.success(this.translate.instant(text), this.translate.instant("s1.toast.success"), { timeOut: 1000 });
    }

    showErrorToast(text: string, title:string = "s1.toast.error") {
      this.toasterService.pop('error', this.translate.instant(title), this.translate.instant(text));
      //this.toasterService.success(this.translate.instant(text), this.translate.instant("s1.toast.success"), { timeOut: 1000 });
    }

}
