import { IProductDiscountInfo, ProductType, ValidityTime } from "./product.interface";

export enum ProductState {
    INACTIVE = 0,
    ACTIVE = 1
}

export enum TranslationAttributeFamilyKey {
    IMG_FAMILY_AREA = "IMG_FAMILY_AREA"
}

export interface ProductGroupFamily {
    id?: number;
    productFamilyImg?: string;
    productFamilyDescription?: string;
    productFamilyName?: string;
    codeFamily?: string;
    products?: Product[];
    translationAttribute?: TranslationAttribute[];
}

export interface Product {
    id?: number;
    idProductType?: number;
    idCompany?: number;
    idProductFamily?: number;
    idPriceList?: number;
    idPriceListProduct?: number;
    idMultipack?: number;
    idAttribute?: number;
    codeFamily?: string;
    multipackName?: string;
    identificationName?: string;
    name?: string;
    typeName?: string;
    urlImg?: string;
    description?: string;
    shortDescription?: string;
    productFamilyName?: string;
    frequency?: number;
    regIva?: string;
    itemCode?: string;
    unitOfMeasure?: string;
    currency?: string;
    price?: number;
    priceByLang?: string;
    finalPrice?: number;
    vat?: number;
    resellerFee?: number;
    state?: ProductState;
    tsCreated?: number;
    tsLastModified?: number;
    multipackProducts?: any[]; // da definire
    translationAttribute?: TranslationAttribute[]; // da definire
    thresholdAttribute?: ThresholdAttribute[];
    vatProduct?: any[]; // da definire
    customFields?: CustomField;
    isCombo?: boolean;
    customFieldsExternal?: CustomFieldsExternalPark | any;
}

export interface CustomField {
    api?: string;
    ticket?: boolean;
    usability?: string;
    ticketType?: string;
    externalTicketType?: string;
    usableTime?: any;
    withdrawal?: any;
    validityTime?: ValidityTime;
    obliterations?: any;
    withdrawalList?: any;
    lastValidityInCart?: number;
    owner?: {id?: number};
    startSelectCalendar: number;
    toleranceFrom: any;
    toleranceTo: any;
    validityTimeCarnet?: ValidityTime;
    usabilityCarnet?: string;
    mailingList?: string;
    combo?: ProductInCombo[];
    cumulativeAccessWithPassenger?: boolean;
    afterSale?: AfterSale;
    reminderEvent?: boolean;
}

export interface CustomFieldsExternalPark {
    accountCode?: boolean,
    aheadTime? : number,
    airport?: string,  
    availablePlaces?: number,
    changeFee?: any,
    checkIn?: Date,
    checkOut?: Date,
    fakeProposal?: boolean,
    feeCalculated?: number,
    feeCode?: string,
    feeFull?: number,
    feePriority?: number,
    feeRule?: string,
    feeRuleDescription?: string,
    feeTypeDescription?: string,
    feeTypeRef?: number,
    hidden?: boolean,
    identificationMode?: string,
    insurance?: boolean,
    maxQuantity?: number,
    parentProposalRef?: any,
    productCode?: string,
    productInformations?: string,
    productType?: string,
    proposalId?: number,
    purchaseTime?: string,
    shortProductDescription?: string,
    terminal?: string,
    uncoverd?: boolean,
    quantitySelected?: number
}

export interface ProductInCombo {
    obliterations: any;
    idProductInCombo: number;
    idProductType: ProductType;
    codeFamilyProductInCombo: string;
    nameProductInCombo: string;
}

export interface ThresholdAttribute {
    id?: number;
    name?: string;
    idProduct?: number;
    tsCreated?: number;
    hasCarnet?: boolean;
    carnet?: number;
    productThresholds?: ProductThreshold[]; // da definire
    cumulative?: boolean;
}

export interface ProductThreshold {
    id?: number;
    idPriceListProduct?: number;
    min?: number;
    max?: number;
    name?: string;
    price?: number;
    resellerFee?: number;
    priceReduced?: number;
    discountIncrease?: IProductDiscountInfo;
}

export interface TranslationAttribute {
    id?: number;
    idProduct?: number;
    key?: string;
    description?: string;
    enabled?: boolean;
    languageCode?: string;
    value?: string;
}

export interface AfterSale {
    modifyBO?: boolean;
    cancelBO?: boolean;
    modifyMP?: boolean;
    cancelMP?: boolean;
}
