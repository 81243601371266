import { Component, OnInit, ViewChild } from '@angular/core';
import { S1Modal, S1ModalSizes } from '../../../s1/components/s1-modal/s1-modal.component';
import { ProductApiService } from '../../services/product-api.service';
import { DiscountState, DiscountType, IDiscount } from '../../models/discount';
import { State } from '../../../core/interfaces/admin.interface';

@Component({
  selector: 'aquamore-product-discounts-modal',
  templateUrl: './product-discounts-modal.component.html',
  styleUrls: ['./product-discounts-modal.component.scss']
})
export class ProductDiscountsModalComponent implements OnInit {

  @ViewChild('modal') modal: S1Modal;

  discountType = DiscountType;
  discountState = DiscountState;
  state = State;

  tableInfo = {
    page: 0,
    row: 10,
    total: 0
  }

  private productId: number;

  elementList: IDiscount[] = [];

  constructor(private productApiService: ProductApiService) { }

  ngOnInit(): void { }

  open(productId: number) {
    this.productId = productId;
    this.modal.open(S1ModalSizes.LG);
    this.search();
  }

  close() {
    this.modal.close();
  }

  search() {

    this.productApiService.listaDiscounts(this.productId).subscribe(discounts => {

      this.elementList = discounts;

    })

  }

  onChangePage(dataPagination: any): void {
    this.tableInfo.page = dataPagination.page - 1;
    this.search();
  }

}
