import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IBattesimoVoloExtraInfo {
  date: Date,
  destination: string,
  airline: string,
  groupLeaderName: string
}

@Component({
  selector: 'aquamore-product-sales-battesimo-volo-section',
  templateUrl: './product-sales-battesimo-volo-section.component.html',
  styleUrls: ['./product-sales-battesimo-volo-section.component.scss']
})
export class ProductSalesBattesimoVoloSectionComponent implements OnInit {

  @Input() hideInputInCommon: boolean = false;
  @Input() info: IBattesimoVoloExtraInfo = {
    date: new Date(),
    destination: null,
    airline: "",
    groupLeaderName: null
  }

  @Output() infoChange = new EventEmitter<IBattesimoVoloExtraInfo>();

  constructor() { }

  ngOnInit() { }

  updateInfo() {
    console.log("Info UPd: ", this.info);
    this.infoChange.emit(this.info);
  }

  validBattVolo(value?): void {
    /*this.addValidity = false;

    const retire = this.inputForm.multiselect.retireOptionType !== null || this.inputForm.multiselect.retireOptionType !== '';

    const list = this.cardList.every(el => el.idAttribute !== null && el.accountHolder !== null && el.accountHolder !== '');

    if (this.inputForm.multiselect.withdrawal[0].itemName) {
      if (this.inputForm.multiselect.withdrawal[0].itemName === RetireOptionOnly.HOME.toUpperCase()) {
        const groupLeaderName = this.inputForm.inputText.groupLeaderName !== null && this.inputForm.inputText.groupLeaderName !== '';
        const destination = this.inputForm.inputText.destination !== null && this.inputForm.inputText.destination !== '';
        const selectedDate = this.inputForm.inputText.selectedDate !== null && this.inputForm.inputText.selectedDate !== '';
        const airline = this.inputForm.inputText.airline !== null && this.inputForm.inputText.airline !== '';
        const city = this.inputForm.inputText.city !== null && this.inputForm.inputText.city !== '';
        const province = this.inputForm.inputText.province !== null && this.inputForm.inputText.province !== '';
        const zipCode = this.inputForm.inputText.zipCode !== null && this.inputForm.inputText.zipCode !== '';
        const address = this.inputForm.inputText.address !== null && this.inputForm.inputText.address !== '';
        console.log('VALID: ', groupLeaderName+"-"+destination+"-"+selectedDate+"-"+airline+"-"+city+"-"+province+"-"+zipCode+"-"+address );
        if (this.cardList.length > 0 && retire && groupLeaderName && destination && selectedDate && airline && city && province && zipCode && address && list) {
          this.addValidity = true;
          return;
        }
      } else {
        if (this.cardList.length > 0 && retire && list) {
          this.addValidity = true;
          return;
        }
      }
    }*/
  }

}
