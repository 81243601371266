import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThresholdAttributeInfo } from '../../../core/interfaces/cart-item.interface';
import { Product, ThresholdAttribute } from '../../../core/interfaces/product-sale.interface';
import { ValidityTime } from '../../../core/interfaces/product.interface';
import { CartService } from '../../../core/services/cart.service';

@Component({
  selector: 'aquamore-product-sales-threshold-section',
  templateUrl: './product-sales-threshold-section.component.html',
  styleUrls: ['./product-sales-threshold-section.component.scss']
})
export class ProductSalesThresholdSectionComponent implements OnInit {

  @Input() product: Product;
  @Input() quantityArray: ThresholdAttributeInfo[] = [];
  @Output() quantityArrayChange = new EventEmitter<ThresholdAttributeInfo[]>();
  
  amountDue: number = 0;
  addValidity: boolean = true;

  constructor(private cartService: CartService) { }

  ngOnInit() { 
    this.product.thresholdAttribute.sort((a, b) => a.tsCreated - b.tsCreated);
  }

  calcAmountDue(): void {
    const notOverMax = this.product.thresholdAttribute.every((thAt, idx) =>
      this.quantityArray[idx].quantity <= this.getMaxThreshold(thAt));

    if (notOverMax) {
      this.amountDue = this.product.isCombo ? this.cartService.calcTotalPriceForThresholdAttributeForCombo(this.product.thresholdAttribute, this.quantityArray) : this.cartService.calcTotalPriceForThresholdAttribute(this.product.thresholdAttribute, this.quantityArray);
    }
    
    this.checkValidity();
    this.checkIfHasTicketNotCarnet();
  }

  checkValidity( event? ): void {
    this.quantityArray.forEach(el => el.quantity = el.quantity !== null ? el.quantity : 0);

    const notOverMax = this.product.thresholdAttribute.every((thAt, idx) =>
      this.quantityArray[idx].quantity <= this.getMaxThreshold(thAt));
    const oneGreaterThenZero = this.quantityArray.some(el => el.quantity !== 0);
    /**
     * La logica di validazione per aggiungere al carrello varia in base al tipo di prodotto
     */
    if( this.product.codeFamily == 'VIP_LOUNGE' ) {
      //this.checkIfHasTicketNotCarnet();
     // this.addValidity = notOverMax && oneGreaterThenZero && (this.dateSelected != null || !this.showDatePicker );
    } else {
      this.addValidity = notOverMax && oneGreaterThenZero;
    }
    this.quantityArrayChange.emit(this.quantityArray);
  }

  getMaxThreshold(thAt: ThresholdAttribute): number {
    let max = 0;
    thAt.productThresholds.forEach(el => {
      max = el.max > max ? el.max : max;
    });
    return max;
  }

  checkIfHasTicketNotCarnet() {
    if(this.quantityArray.some( item => ( item.quantity > 0 ) && this.hasSomeCustomValidity( item.idAttribute ) )) {
      //this.showDatePicker = true;
    } else {
      //this.showDatePicker = false;
    }
  }

  private isCarnet( idThresholdAttribute: number ) {
    return this.product.thresholdAttribute.find(item =>  item.id == idThresholdAttribute ).carnet > 1;
  }

  private isCarnetValidityCustom() {
    return this.product.customFields.validityTimeCarnet == ValidityTime.CUSTOM;
  }

  private hasSomeCustomValidity( idThresholdAttribute: number ) {
    if( this.isCarnet(idThresholdAttribute ) ) {
      return this.product.customFields.validityTimeCarnet == ValidityTime.CUSTOM ;
    } else {
      return this.product.customFields.validityTime == ValidityTime.CUSTOM ;
    }
  }

}
